// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-company-profile-js": () => import("./../../src/pages/about/company-profile.js" /* webpackChunkName: "component---src-pages-about-company-profile-js" */),
  "component---src-pages-about-contact-us-js": () => import("./../../src/pages/about/contact-us.js" /* webpackChunkName: "component---src-pages-about-contact-us-js" */),
  "component---src-pages-about-faqs-js": () => import("./../../src/pages/about/faqs.js" /* webpackChunkName: "component---src-pages-about-faqs-js" */),
  "component---src-pages-accounts-account-types-js": () => import("./../../src/pages/accounts/account-types.js" /* webpackChunkName: "component---src-pages-accounts-account-types-js" */),
  "component---src-pages-accounts-corporate-account-js": () => import("./../../src/pages/accounts/corporate-account.js" /* webpackChunkName: "component---src-pages-accounts-corporate-account-js" */),
  "component---src-pages-accounts-deposit-js": () => import("./../../src/pages/accounts/deposit.js" /* webpackChunkName: "component---src-pages-accounts-deposit-js" */),
  "component---src-pages-accounts-live-account-js": () => import("./../../src/pages/accounts/live-account.js" /* webpackChunkName: "component---src-pages-accounts-live-account-js" */),
  "component---src-pages-accounts-neteller-js": () => import("./../../src/pages/accounts/neteller.js" /* webpackChunkName: "component---src-pages-accounts-neteller-js" */),
  "component---src-pages-accounts-skrill-js": () => import("./../../src/pages/accounts/skrill.js" /* webpackChunkName: "component---src-pages-accounts-skrill-js" */),
  "component---src-pages-accounts-withdrawal-js": () => import("./../../src/pages/accounts/withdrawal.js" /* webpackChunkName: "component---src-pages-accounts-withdrawal-js" */),
  "component---src-pages-home-carousel-js": () => import("./../../src/pages/home/carousel.js" /* webpackChunkName: "component---src-pages-home-carousel-js" */),
  "component---src-pages-home-index-js": () => import("./../../src/pages/home/index.js" /* webpackChunkName: "component---src-pages-home-index-js" */),
  "component---src-pages-home-join-accindex-js": () => import("./../../src/pages/home/join-accindex.js" /* webpackChunkName: "component---src-pages-home-join-accindex-js" */),
  "component---src-pages-home-offers-js": () => import("./../../src/pages/home/offers.js" /* webpackChunkName: "component---src-pages-home-offers-js" */),
  "component---src-pages-home-payment-gateways-js": () => import("./../../src/pages/home/payment-gateways.js" /* webpackChunkName: "component---src-pages-home-payment-gateways-js" */),
  "component---src-pages-home-trading-platforms-js": () => import("./../../src/pages/home/trading-platforms.js" /* webpackChunkName: "component---src-pages-home-trading-platforms-js" */),
  "component---src-pages-home-why-accuindex-js": () => import("./../../src/pages/home/why-accuindex.js" /* webpackChunkName: "component---src-pages-home-why-accuindex-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-onlyfooter-js": () => import("./../../src/pages/onlyfooter.js" /* webpackChunkName: "component---src-pages-onlyfooter-js" */),
  "component---src-pages-partnership-introducing-broker-application-js": () => import("./../../src/pages/partnership/introducing-broker-application.js" /* webpackChunkName: "component---src-pages-partnership-introducing-broker-application-js" */),
  "component---src-pages-slice-simulator-index-js": () => import("./../../src/pages/slice-simulator/index.js" /* webpackChunkName: "component---src-pages-slice-simulator-index-js" */),
  "component---src-pages-trading-economic-calender-js": () => import("./../../src/pages/trading/economic-calender.js" /* webpackChunkName: "component---src-pages-trading-economic-calender-js" */),
  "component---src-pages-trading-education-js": () => import("./../../src/pages/trading/education.js" /* webpackChunkName: "component---src-pages-trading-education-js" */),
  "component---src-pages-trading-platforms-mt-5-mobile-app-js": () => import("./../../src/pages/trading-platforms/mt5-mobile-app.js" /* webpackChunkName: "component---src-pages-trading-platforms-mt-5-mobile-app-js" */),
  "component---src-templates-basic-layout-js": () => import("./../../src/templates/basic-layout.js" /* webpackChunkName: "component---src-templates-basic-layout-js" */)
}

