import React from "react"
import { Provider } from "react-redux"
import configStoreFunction from "./src/reducers/store"

const configStore = configStoreFunction()
// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
  // return <Provider store={store}>{element}</Provider>
  return <Provider store={configStore.store}>{element}</Provider>
}
